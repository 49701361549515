.neonText {
    color: #fff;
    text-shadow:
        0 0 7px #ffffff,
        0 0 10px #ffffff,
        0 0 21px #ffffff,
        0 0 42px #ff2a00,
        0 0 82px #ff2a00,
        0 0 92px #ff2a00,
        0 0 102px #ff2a00,
        0 0 151px #ff2a00;
  }
  
  /* Additional styling */
    
  body {
    font-size: 18px;
    font-family: "Vibur", sans-serif;
  }  
  
  h1, h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
  }
    
  h1 {
      font-size: 4.2rem;
  }
    
  h2 {
      font-size: 1.8rem;
  }
  
  .container_404 {
    margin-top:40vh;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }