.list_holder {
    position: relative;
    width: 100%;
    top:50px;
    overflow-y: auto;

}

.list_search {
    position: fixed;
    width: 100%;
    height: 50px;
    z-index: 9;
    padding: 15px;
    background-color: #1f1f1f;
    border-color: #1f1f1f;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: none;
}

.search_container{
    position: relative;
    justify-content: flex-end;
    display: flex;
    width: 100%;
}

.card {
    position: relative;
    /* height: 100px; */
    /* min-height: 100px; */
    overflow: hidden;
    background: #1f1f1f;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left:auto;
    margin-right:auto;
    transition: 0.4s all;
    color: #fff;
    padding: 20px;
    list-style: none;
    width: 85vw;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .card-light {
    position: relative;
    background: #fff;
    margin: 20px 40px;
    transition: 0.4s all;
    color: #1f1f1f;
    padding: 20px;
    list-style: none;
    width: 85vw;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  @media only screen and (min-width: 600px) {
    .card {
        width: 85vw;
      margin-top:20px;
      margin-bottom:20px;
      margin-left:auto;
      margin-right:auto;
    }

    .card-light{
      width: 85vw;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:auto;
    margin-right:auto;
  }

  }
  
  @media only screen and (max-device-width: 800px) and (orientation: portrait) {
    .card {
      margin: 15px 30px;
      margin-left:auto;
      margin-right:auto;
      width: 85vw;
      
    }

    .card-light{
      margin: 15px 30px;
      margin-left:auto;
      margin-right:auto;
      width: 85vw;
      
    }
  }

  .shadow-1:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: inherit;
    height: inherit;
    z-index: -2;
    box-sizing: border-box;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  }
  
  .shadow-1:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: inherit;
    height: inherit;
    z-index: -2;
    box-sizing: border-box;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  }


  .chckbx-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .chckbx-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .chckbx-group label {
    position: relative;
    cursor: pointer;
  }
  
  .chckbx-group label:before {
    content:'';
    background-color: transparent;
    border: 2px solid #eb5228;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .chckbx-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 13px;
    border: solid #eb5228;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

