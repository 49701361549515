@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');


:root {
    --card-height: calc(40vh + 200px);
    --card-width: 90vw;
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
body{
    padding-bottom: 100px;
    justify-content: center;
    align-items: center;
    /*min-height: 100vh;*/
    background-color: #151719;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #27282c;
}

.box_register{
    max-width: var(--card-width);
    width: 400px;
    min-height: 300px;
    background-color: #27282c;
    border-radius: 8px;
}

.box{
    position: absolute;
    transform: translateY(-50%);
    top:50%;
    max-width: var(--card-width);
    width: 400px;
    min-height: 550px;
    background-color: #27282c;
    border-radius: 8px;
    overflow: hidden;
}
.box::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    max-width: var(--card-width);
    width: 400px;
    max-height: var(--card-height);
    min-height: 500px;
    background: linear-gradient(0deg, transparent,#eb5228,#eb5228);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}
.box::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    max-width: var(--card-width);
    width: 400px;
    max-height: var(--card-height);
    min-height: 500px;
    background: linear-gradient(0deg, transparent,#eb5228,#eb5228);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}
@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

/* Form */
.form{
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #27282c;
    z-index: 10;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
}
.form h2{
    color: #eb5228;
    font-weight: 500;
    font-size:large;
    text-align: center;
    letter-spacing: 0.1em;
}

.form img{
    text-align: center;
    height: 200px; 
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.inputBox{
    position: relative;
    width: 100%;
    margin-top: 35px;
}

.inputBox input{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Poppins', Sans-serif;
    font-size: 20px;
    font-weight: 200;
    z-index: 10;
}

.inputBox input[type="submit"]{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Poppins', Sans-serif;
    font-size: 11px;
    font-weight: 200;
    z-index: 10;
}

.text_input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #27282c;
    font-size: .5em;
    letter-spacing: 0.05em;
    z-index: 10;
}
.inputBox span{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    font-size: 1em;
    color: #8f8f8f;
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
}
.inputBox input:valid ~ span,
.inputBox input:focus ~ span{
    color: #eb5228;
    transform: translateX(0px) translateY(-34px);
    font-size: 0.75em;
}
.inputBox i{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #eb5228;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 0;
}
.register_bkg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #eb5228;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 0;
    height: 44px;
}
.register_label {
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
    color: #eb5228;
    transform: translateX(0px) translateY(-34px);
    font-size: 0.75em;
}

.register_container {
    margin-top: 100px;
    width: 90vw;
    max-height: 100vh;
    top:0;
    position: absolute;
    align-items: flex-start;
}

.inputBox input:valid ~ i,
.inputBox input:focus ~ i{
     height: 44px;
}

.links{
    display: flex;
    justify-content: space-between;

}

.links p {
    margin: 10px 0;
    font-size: 0.75em;
    color: #8f8f8f;
    text-decoration: none;
}
.links p:hover,
.links p:nth-child(2){
    color: #eb5228;
}


input[type="submit"]{
    border: none;
    outline: none;
    background: #eb5228;
    padding: 11px 25px;
    width: 100px;
    height:50px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: 300;
    cursor: pointer;
} 
input[type="submit"]:active{
    opacity: 0.8;
}

.btn[type="submit"]{
    border: none;
    outline: none;
    background: #eb5228;
    padding: 11px 25px;
    width: 100px;
    height:50px;
    margin-top: 10px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
} 
.btn[type="submit"]:active{
    opacity: 0.8;
}

